<template>
	<div class="manageBox">
		<Tabs value="name1" @on-click="chooseTab">
			<TabPane label="用户建议" name="name1">
				<div class="infoTips" v-show="infoNum > 0">
					<img src="../../assets/image/messageTips.png" alt />
					您有 {{ infoNum }}条 已回复未读信息
				</div>

				<div class="topPart">
					<div class="form-item">
						<span class="title">事项</span>
						<Input v-model="params.bt" placeholder="模糊搜索" style="width: 300px; margin-right: 60px" />
						<span class="title">类型</span>
						<Select v-model="params.sxlx" clearable style="width: 300px; margin-right: 60px">
							<Option v-for="item in list" :value="item.value" :key="item.value">{{ item.label }}</Option>
						</Select>
						<span class="title">进展</span>
						<Select v-model="params.zt" clearable style="width: 300px; margin-right: 20px">
							<Option v-for="item in ztList" :value="item.value" :key="item.value">{{ item.label }}
							</Option>
						</Select>
					</div>
					<div class="form-item">
						<span class="title">提交时间</span>
						<DatePicker type="date" format="yyyy-MM-dd" v-model="params.tjsj[0]" @on-change="getTimeStarttj"
							placeholder="开始时间" style="width: 140px; margin-right: 5px"></DatePicker>至
						<DatePicker type="date" format="yyyy-MM-dd" v-model="params.tjsj[1]" @on-change="getTimeEndtj"
							placeholder="结束时间" style="width: 140px; margin: 0 50px 0 5px"></DatePicker>
						<span class="title">处理时间</span>
						<DatePicker :type="'date'" format="yyyy-MM-dd" @on-change="getTimeStart"
							v-model="params.hfsj[0]" placeholder="开始时间" style="width: 140px; margin-right: 5px">
						</DatePicker>至
						<DatePicker :type="'date'" @on-change="getTimeEnd" v-model="params.hfsj[1]" format="yyyy-MM-dd"
							placeholder="结束时间" style="width: 140px; margin: 0 50px 0 5px"></DatePicker>
					</div>
					<Button type="info" @click="search(1)"
						style="width: 140px;height: 40px;margin-left: 80px;">查询</Button>
				</div>

				<Table class="table" tooltip-theme="light" border :columns="columns" :data="data">
					<template slot-scope="{ row }" slot="option">
						<!-- <Button type="text" size='small' @click="goDetail(row)">查看详情</Button> -->
						<span v-if="row.stateName != '未处理'" style="color: #00B6FF;cursor: pointer;"
							@click="goDetail(row)">查看详情</span>
					</template>
				</Table>
			</TabPane>
			<TabPane label="系统消息" name="name2">
				<div class="infoTips" v-show="infoNum2 > 0">
					<img src="../../assets/image/messageTips.png" alt />
					您有 {{ infoNum2 }}条 未读信息
				</div>
				<div class="sysSearch">
					<span class="title">标题</span>
					<Input v-model="params2.bt" placeholder="模糊搜索" style="width: 300px; margin-right: 60px" />
					<Button type="info" @click="search2(1)" style="width: 144px; display: block">查询</Button>
				</div>

				<Table class="table" tooltip-theme="light" border @on-row-click="chooseItem" :columns="columns2"
					:data="showData2"></Table>
			</TabPane>
		</Tabs>

		<Modal class-name='vertical-center-modal' :mask-closable="false" :title='modalTitle' v-model="modal"
			:width='780' :footer-hide='true'>
			<div>
				<div class="option-box">
					<Form label-colon :label-width="80">
						<FormItem label="回复内容">
							<Input disabled type="textarea" :rows="10" maxlength="1000" show-word-limit
								v-model="modalInfo" style="width: 500px;" />
						</FormItem>
						<FormItem label="相关附件">
							<a v-if="replyUrl" :href="replyUrl">下载附件</a>
							{{ replyUrl ? "" : "暂无附件" }}
						</FormItem>

					</Form>
				</div>
				<div style="width: 140px; margin: 40px auto 40px">
					<Button @click.native.prevent="modal = false" type="info" style="width: 140px;height: 40px;"
						class="setPsw" circle>确定</Button>
				</div>
			</div>
		</Modal>

		<Modal class-name='vertical-center-modal' :title='modalTitle2' v-model="modal2" footer-hide width="780">
			<div>
				<div style="margin: 16px">{{ modalInfo2 }}</div>
				<div style="width: 140px; margin: 40px auto 40px">
					<Button @click.native.prevent="modal2 = false" type="info" style="width: 140px;height: 40px;"
						class="setPsw" circle>确定</Button>
				</div>
			</div>
		</Modal>

		<Modal class-name='vertical-center-modal' title='用户建议' v-model="showReAdviceModal" footer-hide width="780">
			<div class="option-box">
				<Form ref="lookForm" label-colon :label-width="60">
					<FormItem prop="Etitle" label="事项">
						{{reAdviceInfo.title}}
					</FormItem>
					<FormItem prop="EadviseTypeName" label="类型">
						{{reAdviceInfo.adviseTypeName}}
					</FormItem>
					<FormItem prop="EcontentName" style='100%' label="内容">
						<div v-if="reAdviceInfo.contentName&&reAdviceInfo.contentName !== []">
							<div class="contentType" :key="item" v-for="item in reAdviceInfo.contentName.split(',')">
								{{item}}
							</div>
						</div>
					</FormItem>
					<FormItem label="补充">
						<Input :disabled="true" type="textarea" :rows="10" maxlength="1000" show-word-limit
							v-model="reAdviceInfo.infoDetail" style="width: 500px;" />
					</FormItem>
				</Form>
			</div>
			<div class="btn-box">
				<Button class="btn" type="info" @click="cancle()">确定</Button>
			</div>
		</Modal>
	</div>
</template>

<script>
	import {
		getUserInfo,
		getSysInfo,
		getUnreadInfo,
		getSysUnreadInfo,
		lookInfo,
		lookSysInfo,
		lookInfoById,
		getInfoById,
	} from "@/api/infoManage";
	import {
		formatTime
	} from "@/utils/index";
	export default {
		data() {
			return {
				modal: false,
				modal2: false,
				modalInfo: "",
				modalTitle: "",
				modalInfo2: "",
				modalTitle2: "",
				params: {
					bt: "",
					sxlx: "",
					zt: "",
					tjsj: [],
					hfsj: [],
				},
				list: [{
						label: "咨询",
						value: 1,
					},
					{
						label: "建议",
						value: 3,
					},
					{
						label: "表扬",
						value: 4,
					},
					{
						label: "投诉",
						value: 2,
					},
					{
						label: "其他",
						value: 6,
					},
				],
				ztList: [{
						label: "未处理",
						value: 1,
					},
					{
						label: "已回复",
						value: 2,
					},
				],
				params2: {
					bt: "",
				},
				replyUrl: "",
				replyName: "",
				columns: [{
						title: "序号",
						type: "index",
						width: 68,
						align: "center",
						render: (h, params) => {
							return h(
								"span", {
									style: {
										"font-weight": params.row.stateName == "未处理" ? "400" : "400",
									},
								},
								params.row.index
							);
						},
					},
					{
						title: "事项",
						key: "title",
						align: "center",
						render: (h, params) => {
							return h(
								"span", {
									style: {
										"font-weight": params.row.stateName == "未处理" ? "400" : "400",
										color: "#2DB7F5",
										cursor: "pointer",
									},
									on: {
										click: () => {
											getInfoById({
												id: params.row.id,
											}).then((res) => {
												this.reAdviceInfo = res;
												this.showReAdviceModal = true;
											});
										},
									},
								},
								params.row.title
							);
						},
					},
					{
						title: "类型",
						key: "adviseTypeName",
						align: "center",
						filters: [{
								label: "咨询",
								value: 1,
							},
							{
								label: "建议",
								value: 2,
							},
							{
								label: "求助",
								value: 3,
							},
							{
								label: "疑问",
								value: 4,
							},
							{
								label: "投诉",
								value: 5,
							},
							{
								label: "其他",
								value: 6,
							},
						],
						filterMultiple: true,
						filterMethod(value, row) {
							if (value === 1) {
								return row.adviseTypeName == "咨询";
							} else if (value === 2) {
								return row.adviseTypeName == "建议";
							} else if (value === 3) {
								return row.adviseTypeName == "求助";
							} else if (value === 4) {
								return row.adviseTypeName == "疑问";
							} else if (value === 3) {
								return row.adviseTypeName == "投诉";
							} else if (value === 4) {
								return row.adviseTypeName == "其他";
							}
						},
						render: (h, params) => {
							return h(
								"span", {
									style: {
										"font-weight": params.row.stateName == "未处理" ? "400" : "400",
									},
								},
								params.row.adviseTypeName
							);
						},
					},
					{
						title: "提交时间",
						key: "gmtCreate",
						align: "center",
						sortable: true,
						render: (h, params) => {
							return h(
								"span", {
									style: {
										"font-weight": params.row.stateName == "未处理" ? "400" : "400",
									},
								},
								params.row.gmtCreate ? formatTime(params.row.gmtCreate) : ""
							);
						},
					},
					{
						title: "进展",
						key: "stateName",
						align: "center",
						filters: [{
								label: "未处理",
								value: 1,
							},
							{
								label: "已回复",
								value: 2,
							},
						],
						filterMultiple: false,
						filterMethod(value, row) {
							if (value === 1) {
								return row.stateName == "未处理";
							} else if (value === 2) {
								return row.stateName == "已回复";
							}
						},
						render: (h, params) => {
							return h(
								"span", {
									style: {
										"font-weight": params.row.stateName == "未处理" ? "400" : "400",
									},
								},
								params.row.stateName
							);
						},
					},
					{
						title: "处理时间",
						key: "replyTime",
						align: "center",
						sortable: true,
						render: (h, params) => {
							return h(
								"span", {
									style: {
										"font-weight": params.row.stateName == "未处理" ? "400" : "400",
									},
								},

								params.row.replyTime ? formatTime(params.row.replyTime) : ""
							);
						},
					},
					{
						title: "回复内容",
						key: "option",
						width: 261,
						slot: 'option',
					},
				],
				columns2: [{
						title: "序号",
						type: "index",
						width: 68,
						align: "center",
					},
					{
						title: "标题",
						key: "title",
						align: "center",
						render: (h, params) => {
							return h(
								"span", {
									style: {
										"font-weight": params.row.stateName === "未读" ? "900" : "400",
										color: "#2DB7F5",
										cursor: "pointer",
									},
								},
								params.row.title
							);
						},
					},

					{
						title: "状态",
						key: "stateName",
						align: "center",
						filters: [{
								label: "已读",
								value: 1,
							},
							{
								label: "未读",
								value: 2,
							},
						],
						filterMultiple: false,
						filterMethod(value, row) {
							if (value === 1) {
								return row.stateName == "已读";
							} else if (value === 2) {
								return row.stateName == "未读";
							}
						},
						render: (h, params) => {
							return h(
								"span", {
									style: {
										"font-weight": params.row.stateName === "未读" ? "900" : "400",
									},
								},
								params.row.stateName
							);
						},
					},
					{
						title: "时间",
						key: "pushTime",
						align: "center",
						sortable: true,
						render: (h, params) => {
							return h(
								"span", {
									style: {
										"font-weight": params.row.stateName === "未读" ? "900" : "400",
									},
								},
								params.row.pushTime ? formatTime(params.row.pushTime) : ""
							);
						},
					},
				],
				data: [],
				originData2: [],
				showData2: [],
				infoNum: 0,
				infoNum2: 0,
				showReAdviceModal: false,
				reAdviceInfo: {},
			};
		},
		computed: {
			// showReAdviceModal: {
			// 	get() {
			// 		return this.$store.state.modal.showReAdviceModal;
			// 	},
			// 	set(newValue) {
			// 		this.$store.state.modal.showReAdviceModal = newValue;
			// 	}
			// },
			// reAdviceInfo: {
			// 	get() {
			// 		return this.$store.state.reAdviceInfo;
			// 	},
			// 	set(newValue) {
			// 		this.$store.state.reAdviceInfo = newValue;
			// 	},
			// },
		},
		mounted() {
			getSysUnreadInfo().then((res) => {
				this.infoNum2 = res;
			});
			getUnreadInfo().then((res) => {
				this.infoNum = res;
			});
			this.search(1);
			this.search2(1);
		},
		methods: {
			cancle(){
				this.showReAdviceModal = false
			},
			goDetail(obj) {
				this.modalInfo = obj.replyInfo;
				this.modalTitle = obj.title
				if (obj.replyUrl) {
					this.replyName = obj.replyUrl
						.split("filename=")
						.pop();
					this.replyUrl = obj.replyUrl;
				} else {
					this.replyName = "";
					this.replyUrl = "";
				}
				lookInfoById({
					id: obj.id
				}).then(() => {
					getUnreadInfo().then((res) => {
						this.infoNum = res;
					});
				});
				this.modal = true;
			},
			chooseTab(name) {},
			changeType() {
				this.stockTable = [];
				this.current = 0;
			},
			getTimeStarttj(value) {
				this.$set(this.params.tjsj, 0, value);
			},
			getTimeEndtj(value) {
				this.$set(this.params.tjsj, 1, value);
			},
			getTimeStart(value) {
				this.$set(this.params.hfsj, 0, value);
			},
			getTimeEnd(value) {
				this.$set(this.params.hfsj, 1, value);
			},
			search(page) {
				let data = {
					adviseType: this.params.sxlx,
					current: page,
					infoState: this.params.zt,
					replyTime: [],
					size: 100000000,
					submitTime: [],
					title: this.params.bt,
				};
				data.replyTime = [...this.params.hfsj];
				data.submitTime = [...this.params.tjsj];
				getUserInfo(data).then((res) => {
					this.data = res.records;
				});
				getUnreadInfo().then((res) => {
					this.infoNum = res;
				});
			},
			initData() {
				getUserInfo();
			},
			chooseItem(row, index) {
				lookSysInfo({
					id: row.id
				}).then(() => {
					getSysUnreadInfo().then((res) => {
						this.infoNum2 = res;
					});
				});
				this.modal2 = true;
				this.modalInfo2 = row.infoDetail;
				this.modalTitle2 = row.title
			},
			search2(page) {
				getSysInfo({
					current: page,
					size: 100000000,
					title: this.params2.bt.trim()
				}).then((res) => {
					this.showData2 = res.records;
				});
				getSysUnreadInfo().then((res) => {
					this.infoNum2 = res;
				});
			},
			localSearch() {
				// if (!this.params2.bt.trim()) return;
				// let arr = [];
				// this.originData2.map((item) => {
				// 	if (item.title.indexOf(this.params2.bt.trim()) > -1) {
				// 		return arr.push(item);
				// 	}
				// });
				// this.showData2 = arr;
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import "@/style/common.scss";

	.manageBox {
		width: 100%;
		height: 100%;
		padding: 20px;
		box-sizing: border-box;

		/deep/.ivu-tabs-ink-bar {
			background: #00b6ff !important;
			height: 3px !important;
		}

		/deep/.ivu-tabs-nav .ivu-tabs-tab-active {
			color: #00b6ff !important;
		}

		/deep/.ivu-tabs-nav .ivu-tabs-tab {
			font-size: 24px;
			font-weight: bold;
		}

		/deep/.infoTips {
			height: 40px;
			background: rgba(255, 244, 244, 1);
			width: 100%;
			display: flex;
			align-items: center;
			box-sizing: border-box;
			padding: 10px 47px;
			color: #f52c2b;

			img {
				width: 17px;
				height: 20px;
				margin-right: 10px;
			}
		}

		.topPart {
			width: 100%;
			height: 200px;
			margin: 20px 0;
			background: rgba(255, 255, 255, 1);
			border-radius: 10px;
			border: 1px solid rgba(227, 227, 229, 1);
			padding: 20px 30px;

			.form-item {
				margin-bottom: 20px;
				@include flex(flex-start);

				.title {
					font-size: 14px;
					font-family: MicrosoftYaHei;
					color: rgba(102, 102, 102, 1);
					line-height: 19px;
					margin-right: 20px;
					width: 60px;
					text-align: right;
				}

				.addIssue {
					position: relative;

					.seletcTypeBox {
						position: absolute;
						left: -5px;
						top: 20px;
						height: 40px;
						display: none;
						background: #fff;
						border: 1px solid rgba(227, 227, 229, 1);
						padding: 10px;
						z-index: 100;
					}

					&:hover {
						.seletcTypeBox {
							display: block;
						}
					}
				}
			}
		}

		.sysSearch {
			display: flex;
			align-items: center;
			margin: 25px 0;

			.title {
				margin-right: 20px;
			}
		}
	}

	.enclosure {
		margin-top: 20px;
		font-size: 14px;
		color: #666666;
		margin-right: 10px;
		text-align: left;

		span {
			color: #999999;
		}
	}

	.option-box {
		padding: 20px 90px 0;
	}

	/deep/.vertical-center-modal {
		display: flex;
		align-items: center;
		justify-content: center;

		.ivu-modal {
			top: 0;
		}
	}

	/deep/.ivu-input[disabled],
	fieldset[disabled] .ivu-input {
		color: #333333;
	}

	.option-box {
		padding: 20px 90px;
	}
	.btn-box {
		text-align: center;
		.btn {
			width: 140px;
			height: 40px;
		}
	}

	.contentType {
		font-size: 14px;
		font-weight: 400;
		padding: 4px 10px;
		border-radius: 8px;
		color: #333333;
		background-color: rgba($color: #999999, $alpha: 0.05);
		width: auto;
		text-align: left;
		display: inline-block;
		margin: 3px;
		width: 48%;
	}
</style>
